import { FLEX_LAYOUT } from '@constants/styleLayout';

export const initialSettingState = {
    isShowBuyAddressInfosCountField: false,
    itemFieldLeftFlexLayout: FLEX_LAYOUT.SPACE_BETWEEN,
    // loadingType: LOADING_TYPE.FULL_PAGE,
    isPayMethodUnderTraderName: true,
    isBtnGroupAsideMainComponent: false,
    isShowBuySellPrices: false
};
