import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import cx from 'classnames';

import useStepper from '@components/Stepper/hook';

import './Stepper.scss';
import { TickIco } from '@images';

export default function Stepper({ stepNames, currStep }) {
    const { t } = useTranslation();
    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);
    const isAllCompleted = currStepIndex === camelStepNames.length - 1;

    return (
        <Box className="stepper">
            {/* {!<LogoBrand />} */}

            <Box className="stepsWrapper">
                {camelStepNames.map((camelStepName, index) => {
                    const isCompletedStep = index < currStepIndex;
                    const isCurrIndex = index === currStepIndex;
                    // const isLastStep = index === camelStepNames.length - 1;
                    return (
                        <Box
                            className={cx([
                                'stepItm',
                                { currStep: isCurrIndex },
                                { completedStep: isCompletedStep }
                            ])}
                            key={index}>
                            <Box className="stepText">
                                <Box className="stepIndicator">
                                    {isAllCompleted || isCompletedStep ? (
                                        <Box className="stepFinished">
                                            <TickIco className="icon iconTick" />
                                        </Box>
                                    ) : (
                                        <Box className="stepIndex">
                                            {index + 1}
                                        </Box>
                                    )}
                                </Box>
                                <Box className="stepName">
                                    {t('common.step.' + camelStepNames[index])}
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}
